import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const HowWorksTeam = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="pt-20 pb-12 lg:pb-16 xl:pb-20 px-20 lg:px-40 2xl:px-52">
                <div className="flex justify-between items-start">
                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Team Workflows</p>
                    <img
                        className="w-[250px] lg:w-[280px] xl:w-[400px] 3xl:w-[500px] xl:mr-16"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/how_works/3_icons_subnav.png"
                        alt="Eterna Agent Icon"
                    />
                </div>
                <div>
                    <p className="text-[14px] lg:text-[18px] xl:text-[22px]">How Eterna Works?</p>
                </div>
                <div>
                    <div className="pt-10 lg:pt-14 xl:pt-20 pl-6 lg:pl-6 xl:pl-14">
                        <img
                            className="w-[350px] lg:w-[440px] xl:w-[600px] 3xl:w-[700px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/images/how_works/3_eterna_text_1.png"
                            alt="Eterna Text 1" // Added alt
                        />
                    </div>
                    <div>
                        <div className="-mt-6 xl:-mt-9 -ml-2">
                            <img
                                className="w-[220px] lg:w-[260px] xl:w-[400px] 3xl:w-[500px]"
                                src="https://storage.googleapis.com/eterna-landing/assets/images/how_works/3_eterna_phone_1.png"
                                alt="Eterna Phone 1" // Added alt
                            />
                        </div>
                        <div className="-mt-60 lg:-mt-72 xl:-mt-[435px] 3xl:-mt-[530px] ml-48 lg:ml-52 xl:ml-80 3xl:ml-[400px]">
                            <img
                                className="w-[220px] lg:w-[260px] xl:w-[420px] 3xl:w-[500px]"
                                src="https://storage.googleapis.com/eterna-landing/assets/images/how_works/3_eterna_phone_2.png"
                                alt="Eterna Phone 2" // Added alt
                            />
                        </div>
                        <div className="-mt-60 lg:-mt-72 xl:-mt-[450px] 3xl:-mt-[530px] ml-96 lg:ml-[430px] xl:ml-[700px] 3xl:ml-[840px]">
                            <img
                                className="w-[220px] lg:w-[260px] xl:w-[400px] 3xl:w-[500px]"
                                src="https://storage.googleapis.com/eterna-landing/assets/images/how_works/3_eterna_phone_3.png"
                                alt="Eterna Phone 3" // Added alt
                            />
                        </div>
                    </div>
                </div>
                <div className="pl-12 pt-16 lg:pt-20 xl:pt-28 flex justify-start items-center space-x-3">
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/collaboration")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Collaboration workflows</p>
                        <img
                            className="w-[20px] lg:w-[30px] xl:w-[55px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png"
                            alt="Right Arrow Icon" // Added alt
                        />
                    </button>
                    <button
                        className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                        onClick={() => navigate("/how_works/conversation")}
                    >
                        <p className="text-[8px] lg:text-[12px] xl:text-[16px] font-light">Create conversation flow</p>
                        <img
                            className="w-[20px] lg:w-[30px] xl:w-[55px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png"
                            alt="Right Arrow Icon" // Added alt
                        />
                    </button>
                    <button onClick={() => navigate("/agents")}>
                        <img
                            className="w-[110px] lg:w-[150px] xl:w-[250px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/how_works/3_eterna_button.png"
                            alt="Eterna Button" // Added alt
                        />
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HowWorksTeam;