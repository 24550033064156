import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const LearnMore = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-eterna-p4 bg-center bg-cover pt-14 pb-12 lg:pb-16 xl:pb-20 px-20 lg:px-40 xl:px-56">
                <div className="flex justify-between items-center">
                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Learn More</p>
                    <img
                        className="w-[80px] lg:w-[120px] xl:w-[160px] cursor-pointer"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_eterna_icon.png"
                        alt="Eterna Icon"
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="pt-6 lg:pt-8 xl:pt-14">
                    <img className="w-[800px] xl:w-[1000px] 2xl:w-[1400px] m-auto" src="https://storage.googleapis.com/eterna-landing/assets/images/learn_more/2_eterna_fondo_1.png" />
                    <div className="pl-12">
                        <div>
                            <p className="text-[28px] lg:text-[35px] xl:text-[45px]">35%</p>
                            <p className="text-[16px] lg:text-[20px] xl:text-[30px] font-light">
                                of the workforce instantly adapts with Eterna.
                            </p>
                        </div>
                        <div className="pt-12 lg:pt-16 xl:pt-20">
                            <button
                                className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                                onClick={() => navigate("/learn_more/agent")}
                            >
                                <p className="text-[12px] lg:text-[16px] xl:text-[20px] font-light">Continue</p>
                                <img className="w-[15px] lg:w-[35px] xl:w-[55px]" src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default LearnMore;