import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const LearnMoreAdoption = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="pt-14 pb-12 lg:pb-16 xl:pb-20 px-20 lg:px-40 2xl:px-56">
                <div className="flex justify-between items-start">
                    <p className="text-[28px] lg:text-[35px] xl:text-[45px]">And what about adoption?</p>
                    <img
                        className="w-[80px] lg:w-[120px] xl:w-[160px] cursor-pointer"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_eterna_icon.png"
                        alt="Eterna Icon"
                        onClick={() => navigate("/")}
                    />
                </div>
                <div>
                    <p className="text-[14px] lg:text-[18px] xl:text-[22px]">Learn More</p>
                </div>
                <div className="-mt-24">
                    <img className="w-[800px] xl:w-[1000px] 2xl:w-[1400px] ml-32 xl:ml-44" src="https://storage.googleapis.com/eterna-landing/assets/images/learn_more/2_eterna_fondo_4.png" alt="Background Cellphone" />
                </div>
                <div className="pl-12">
                    <div className="flex justify-start items-center space-x-3">
                        <button
                            className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                            onClick={() => navigate("/learn_more/agent")}
                        >
                            <p className="text-[12px] lg:text-[16px] xl:text-[20px] font-light">Go Back</p>
                            <img className="w-[15px] lg:w-[35px] xl:w-[55px]" src="https://storage.googleapis.com/eterna-landing/assets/icons/learn_more/2_icon_arrow.png" alt="Left Arrow Icon" />
                        </button>
                        <button
                            className="w-[150px] lg:w-[200px] xl:w-[300px] flex justify-between items-center px-3 xl:px-5 py-2 lg:py-3 xl:py-4 border border-zinc-600 rounded-xl"
                            onClick={() => navigate("/how_works")}
                        >
                            <p className="text-[12px] lg:text-[16px] xl:text-[20px] font-light">How Eterna work's?</p>
                            <img className="w-[15px] lg:w-[35px] xl:w-[55px]" src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png" alt="Right Arrow Icon" />
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default LearnMoreAdoption;