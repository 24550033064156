import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const AgentsLegal = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="relative bg-eterna-p9 bg-center bg-cover">
                <div className="absolute right-0">
                    <img
                        className="w-[80px] lg:w-[120px] xl:w-[150px] mt-20 mr-60 cursor-pointer"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_eterna_icon.png"
                        onClick={() => navigate("/")}
                        alt="Eterna Icon"
                    />
                </div>
                <div className="grid md:grid-cols-2 pb-64">
                    <div className="w-full flex justify-end pt-56 backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[520px]">
                            <div className="pl-8 font-sans">
                                <p className="text-[28px] lg:text-[35px] xl:text-[45px]">Legal</p>
                            </div>
                            <div className="pt-2 pb-12 pl-8">
                                <p className="text-[18px] lg:text-[22px] xl:text-[30px] font-light">Meet the Legal agent.</p>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="flex justify-end backdrop-blur-xl">
                        <div className="w-[350px] lg:w-[420px] xl:w-[530px] relative pl-8 pr-10 py-8 lg:pt-10 xl:py-7 rounded-xl">
                            <div className="absolute inset-0 bg-opacity-25 w-full h-full bg-white rounded-l-xl border-l border-y border-white"></div>
                            <p className="relative text-[11.5px] lg:text-[15px] xl:text-[19px] font-light">
                                Hello! I'm your Eterna Legal Agent, here to help you automate legal processes like finding
                                contracts, creating new agreements, and managing signatures all through WhatsApp.
                            </p>
                            <p className="relative pt-8 text-[11.5px] lg:text-[15px] xl:text-[19px] font-light">
                                I can also assist you in uploading users for contract signing and keeping track of
                                important legal deadlines.
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center bg-opacity-25 bg-white border-y border-white"></div>
                </div>
            </div>
            <div className="relative">
                <div className="w-full -mt-52 lg:-mt-48 xl:-mt-72 mb-10">
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-start lg:-ml-10 xl:-ml-20">
                            <img
                                className="w-[350px] lg:w-[380px] xl:w-[530px] m-auto"
                                src="https://storage.googleapis.com/eterna-landing/assets/images/agents/4_eterna_phone_3.png"
                                alt="Eterna Phone 3"
                            />
                        </div>
                        <div className="space-y-1 -mt-8 ml-40">
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_1.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/finance")}
                                alt="Finance Agent Button"
                            />
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_2.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/human")}
                                alt="Human Resources Agent Button"
                            />
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_3.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/personal")}
                                alt="Personal Agent Button"
                            />
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_4.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/profit")}
                                alt="Profit Agent Button"
                            />
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_6.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                onClick={() => navigate("/agents/business")}
                                alt="Business Agent Button"
                            />
                            <img
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/agents/4_eterna_btn_7.png"
                                className="w-[200px] lg:w-[280px] xl:w-[350px] cursor-pointer"
                                alt="Agent Button"
                            />
                            <div className="pt-1">
                                <p className="text-base lg:text-lg xl:text-xl">All agents, one WhatsApp line.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AgentsLegal;