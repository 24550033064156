import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="bg-[#383737] py-8 md:py-12">
            <div className="container mx-auto flex flex-col items-center justify-center">
                {/* Main container: flex-col and centered */}

                {/* Web Layout */}
                <div className="hidden md:flex md:flex-col items-center">
                    {/* Flex column for web layout */}
                    <div className="flex flex-row items-center md:space-x-16 lg:space-x-24 ">
                        {/* Icon row */}
                        <div>
                            <img
                                className="w-[90px] sm:w-[110px] md:w-[130px] lg:w-[150px] cursor-pointer"
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_eterna.png"
                                onClick={() => navigate("/")}
                                alt="Eterna"
                            />
                        </div>
                        <div>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/eterna-digital/?originalSubdomain=mx"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="w-[70px] sm:w-[90px] md:w-[110px] lg:w-[130px]"
                                    src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_linkedin.png"
                                    alt="LinkedIn"
                                />
                            </a>
                        </div>
                        <div>
                            <img
                                className="w-[80px] sm:w-[100px] md:w-[120px] lg:w-[140px]"
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_whatsapp.png"
                                alt="WhatsApp"
                            />
                        </div>
                        <div className="mt-2">
                            {/* Spacing for Privacy Policy */}
                            <img
                                className="w-[140px] sm:w-[160px] md:w-[180px] lg:w-[200px] cursor-pointer"
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_privacy.png"
                                onClick={() => navigate("/privacy_policy")}
                                alt="Privacy Policy"
                            />
                        </div>
                    </div>
                    <div className="mt-10">
                        {/* Spacing for Eterna 2024 */}
                        <img
                            className="w-[110px] sm:w-[130px] md:w-[150px] lg:w-[170px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_eterna2024.png"
                            alt="Eterna 2024"
                        />
                    </div>
                </div>

                {/* Mobile Layout */}
                <div className="md:hidden flex flex-col items-center space-y-4 w-full">
                    {/* Flex column and centered */}
                    <div className="flex flex-row items-center justify-center space-x-4 w-full">
                        {/* Icon row, centered */}
                        <div>
                            <img
                                className="w-[90px] sm:w-[110px] cursor-pointer"
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_eterna.png"
                                onClick={() => navigate("/")}
                                alt="Eterna"
                            />
                        </div>
                        <div>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/eterna-digital/?originalSubdomain=mx"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="w-[70px] sm:w-[90px]"
                                    src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_linkedin.png"
                                    alt="LinkedIn"
                                />
                            </a>
                        </div>
                        <div>
                            <img
                                className="w-[80px] sm:w-[100px]"
                                src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_whatsapp.png"
                                alt="WhatsApp"
                            />
                        </div>
                    </div>
                    <div className="mt-">
                        {/* Spacing for Eterna 2024 */}
                        <img
                            className="w-[110px] sm:w-[130px]"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_eterna2024.png"
                            alt="Eterna 2024"
                        />
                    </div>
                    <div>
                        {/* Privacy Policy (centered) */}
                        <img
                            className="w-[140px] sm:w-[160px] cursor-pointer"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_privacy.png"
                            onClick={() => navigate("/privacy_policy")}
                            alt="Privacy Policy"
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;