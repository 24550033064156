import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HomeForm from "./HomeForm";
import Loader from "../../components/loader/Loader";
import { sendMail } from "../../services/Mail";
import { toast } from "react-toastify";
import { alertOptions } from "../../utils/Utilities";

const Home = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [clearForm, setClearForm] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSend = (dataObject) => {
        setLoader(true);
        sendMail(dataObject)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("¡Correo enviado con éxito!", alertOptions);
                    setClearForm(true);
                } else {
                    toast.error(
                        "¡Ha ocurrido un error al enviar la información!",
                        alertOptions
                    );
                }
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
                toast.error(
                    "¡Ha ocurrido un error al enviar la información!",
                    alertOptions
                );
            });
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div className="bg-eterna-p2 bg-center bg-cover">
                <div className="grid md:grid-cols-2 pt-8 sm:pt-16 lg:pt-32 pb-16 sm:pb-32 lg:pb-64 gap-4 lg:gap-8">
                    <div className="w-full flex justify-end backdrop-blur-xl">
                        <div className="w-[90%] sm:w-[350px] lg:w-[420px] xl:w-[520px]">
                            <div className="pl-2 sm:pl-5 font-sans">
                                <p className="text-2xl sm:text-[28px] lg:text-[35px] xl:text-[45px] leading-tight">
                                    Omnichannel
                                </p>
                                <p className="mt-[-0.25rem] sm:mt-[-1px] xl:-mt-3 text-2xl sm:text-[28px] lg:text-[35px] xl:text-[45px] leading-tight">
                                    AI Agents unifying
                                </p>
                                <p className="mt-[-0.25rem] sm:mt-[-1px] xl:-mt-3 text-2xl sm:text-[28px] lg:text-[35px] xl:text-[45px] leading-tight">
                                    enterprise technologies
                                </p>
                            </div>
                            <div className="pt-2 sm:pt-3 pb-4 sm:pb-6 pl-2 sm:pl-5">
                                <p className="text-base sm:text-[18px] lg:text-[22px] xl:text-[30px] font-light">
                                    that your workforce will love
                                </p>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="flex justify-end backdrop-blur-xl">
                        <div className="w-[90%] sm:w-[350px] lg:w-[420px] xl:w-[520px] relative pl-4 sm:pl-14 pr-4 sm:pr-10 py-4 sm:py-8 lg:pt-10 xl:py-12 rounded-xl">
                            <div className="absolute inset-0 bg-opacity-25 w-full h-full bg-white rounded-l-xl border-l border-y border-white"></div>
                            <p className="relative text-xs sm:text-[11.5px] lg:text-[15px] xl:text-[19px] font-light leading-relaxed">
                                Imagine an agent that connects to existing systems and can
                                respond instantly to team requests, pulling exactly what's
                                needed from those tools. Our agents automate processes, track
                                status updates, and manage operations.
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex justify-center items-center bg-opacity-25 bg-white border-y border-white order-last py-12">
                        <img
                            className="w-[130px] sm:w-[80px] lg:w-[120px] xl:w-[160px] m-4 cursor-pointer"
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_eterna_icon.png"
                            onClick={() => navigate("/")}
                            alt="Eterna Icon"
                        />
                    </div>
                </div>
            </div>

            <div className="relative bg-eterna-p3 bg-center bg-cover">
                <div
                    className={`w-full ${
                        isMobile
                            ? "mt-[-6rem] sm:mt-[-8rem]"
                            : "mt-[-8rem] sm:mt-[-10rem] lg:-mt-40 xl:-mt-48"
                    }`}
                >
                    <div
                        className={`flex ${
                            isMobile
                                ? "flex-col items-center"
                                : "flex-row items-center justify-center"
                        }`}
                    >
                        <div className="w-full relative">
                            <div className="absolute w-[90%] sm:w-[300px] ml-16 sm:ml-80 -inset-2 rounded-lg bg-gradient-to-r from-[#BC93F6] via-[#BC93F6] to-[#9BEBFF] opacity-10 blur"></div>
                            <div className="relative">
                                <div className="w-full flex justify-center items-center">
                                    <img
                                        className="w-[80%] sm:w-[350px] lg:w-[380px] xl:w-[430px] mt-2 sm:ml-28 xl:ml-36"
                                        src="https://storage.googleapis.com/eterna-landing/assets/images/home/1_phone_home.png"
                                        alt="Phone Image"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={`w-full ${isMobile ? "mt-4" : "mt-0"}`}>
                            <div
                                className={`w-[90%] sm:w-[300px] lg:w-[400px] xl:w-[500px] bg-white space-y-3 p-4 sm:p-5 rounded-xl mx-auto ${
                                    isMobile ? "" : "ml-4"
                                }`}
                            >
                                <div
                                    className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/learn_more")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">
                                        Learn more
                                    </p>
                                    <img
                                        className="w-[40px] lg:w-[60px] xl:w-[80px]"
                                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png"
                                        alt="Arrow"
                                    />
                                </div>
                                <div
                                    className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/how_works")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">
                                        How Eterna works?
                                    </p>
                                    <img
                                        className="w-[40px] lg:w-[60px] xl:w-[80px]"
                                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png"
                                        alt="Arrow"
                                    />
                                </div>
                                <div
									className="flex justify-between items-center p-5 border border-zinc-200 rounded-xl cursor-pointer"
                                    onClick={() => navigate("/agents")}
                                >
                                    <p className="text-[20px] lg:text-[26px] xl:text-[32px] font-light">
                                        Eterna Agents
                                    </p>
                                    <img
                                        className="w-[40px] lg:w-[60px]xl:w-[80px]"
                                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_Icon_flecha_home.png"
                                        alt="Arrow"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`mt-[-2rem] sm:mt-[-10px] flex justify-center items-center ${
                        isMobile ? "hidden" : ""
                    }`}
                >
                    <img
                        className="w-[10px] sm:w-[15px] lg:w-[20px] xl:w-[30px]"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_arrow_down.png"
                        alt="Down Arrow"
                    />
                </div>

                <div className="mt-8 sm:mt-16">
                    <img
                        className="w-[90%] sm:w-[660px] lg:w-[750px] xl:w-[950px] m-auto"
                        src="https://storage.googleapis.com/eterna-landing/assets/images/home/1_letters_eterna.png"
                        alt="Letters Image"
                    />
                </div>

                <div
                    className={`mt-[-2rem] sm:mt-[-10px] flex justify-center items-center ${
                        isMobile ? "hidden" : ""
                    }`}
                >
                    <img
                        className="w-[10px] sm:w-[15px] lg:w-[20px] xl:w-[30px]"
                        src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_arrow_down.png"
                        alt="Down Arrow"
                    />
                </div>

                <div
                    className={`mt-5 mb-10 flex ${
                        isMobile
                            ? "flex-col items-center"
                            : "flex-row justify-center items-start"
                    }`}
                >
                    <div
                        className={`mt-5 mb-10 flex ${
                            isMobile ? "flex-col items-center" : "flex-row items-start"
                        }`}
                    >
                        <div className="w-full relative">
                            <div className="relative">
                                <div className="w-full flex justify-center items-center">
                                    <img
                                        className="max-w-[80%] sm:max-w-[340px] md:max-w-[370px] lg:max-w-[400px] xl:max-w-[420px] mt-2 sm:ml-0 lg:ml-28 xl:ml-36 object-contain"
                                        src="https://storage.googleapis.com/eterna-landing/assets/images/home/1_phone_home_2.png"
                                        alt="Phone 2"
                                    />
                                </div>
                            </div>
                        </div>
                        <img
                            className={`relative xl:-translate-x-[38%] lg:-translate-x-[49%] sm:-translate-x-[60%]
                                xl:-translate-y-[-12%] lg:-translate-y-[-8%] sm:-translate-y-[-42%]
                                xl:max-w-[500px] lg:max-w-[500px] sm:max-w-[350px] object-contain z-10 ${
                                    isMobile ? "hidden" : ""
                                }`}
                            src="https://storage.googleapis.com/eterna-landing/assets/icons/home/1_icon_arrows.png"
                            alt="Arrows"
                        />
                        <div
                            className={`w-full ${isMobile ? "mt-4" : "mt-0"} ${
                                isMobile ? "" : "order-last"
                            }`}
                        >
                            <div
                                className={`relative xl:-translate-x-[38%] lg:-translate-x-[49%] sm:-translate-x-[60%]
                                xl:max-w-[500px] lg:max-w-[500px] sm:max-w-[350px] mx-auto${
                                    isMobile ? "mt-4" : "mt-0 -translate-x-[65%]"
                                }`}
                            >
                                <HomeForm
                                    clearForm={clearForm}
                                    setClearForm={setClearForm}
                                    onSend={handleSend}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;